define('ember-notify/message', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({
    message: null,
    raw: '',
    type: 'info',
    closeAfter: undefined,
    visible: undefined
  });

});