define('ember-cli-nouislider/components/range-slider', ['exports', 'ember', 'noUiSlider'], function (exports, Ember, noUiSlider) {

  'use strict';

  var on = Ember['default'].on;
  var run = Ember['default'].run;
  var isEmpty = Ember['default'].isEmpty;
  var computed = Ember['default'].computed;
  var observer = Ember['default'].observer;

  exports['default'] = Ember['default'].Component.extend({
    slider: null,
    start: undefined,
    step: undefined,
    margin: undefined,
    limit: undefined,
    pips: undefined,
    animate: true,
    snap: false,
    connect: false,
    orientation: 'horizontal',
    direction: 'ltr',
    behaviour: 'tap',

    min: 0,
    max: 100,

    range: computed('min', 'max', function () {
      return {
        min: this.get('min'),
        max: this.get('max')
      };
    }),

    formatTo: function formatTo(value) {
      return value;
    },
    formatFrom: function formatFrom(value) {
      return value;
    },

    format: computed('formatTo', 'formatFrom', function () {
      return {
        to: this.get('formatTo'),
        from: this.get('formatFrom')
      };
    }),

    setup: on('didInsertElement', function () {
      var _this = this;

      var $this = this.$().get(0);
      var properties = this.getProperties('start', 'step', 'margin', 'limit', 'range', 'connect', 'orientation', 'direction', 'behaviour', 'animate', 'snap', 'pips', 'format');
      var sliderEvents = Ember['default'].A(['change', 'set', 'slide', 'update', 'start', 'end']);

      noUiSlider['default'].create($this, properties);

      var slider = $this.noUiSlider;
      this.set('slider', slider);

      sliderEvents.forEach(function (event) {
        if (!isEmpty(_this.get('on-' + event))) {
          slider.on(event, function () {
            run(_this, function () {
              var val = this.get("slider").get();
              this.sendAction('on-' + event, val);
            });
          });
        }
      });

      /** DEPRECATED AND WILL BE REMOVED BEFORE 1.0 **/
      slider.on('change', function () {
        run(_this, function () {
          var val = this.get("slider").get();
          this.sendDeprecatedAction("change", val);
        });
      });

      if (!isEmpty(this.get('slide'))) {
        slider.on('slide', function () {
          run(_this, function () {
            var val = this.get("slider").get();
            this.sendDeprecatedAction('slide', val);
          });
        });
      }
    }),

    teardown: on('willDestroyElement', function () {
      var slider = this.get('slider');

      slider.off('change');
      slider.off('slide');
      slider.off('set');
      slider.off('update');
      slider.off('start');
      slider.off('end');

      slider.destroy();
    }),

    setVal: observer('start', function () {
      var slider = this.get('slider');

      if (slider) {
        var val = this.get('start');
        slider.set(val);
      }
    }),

    /**
     * Perform a naive check to see if the deprecated action name exists in our
     * attrs and then log a deprecation warning and trigger the old action.
     */
    sendDeprecatedAction: function sendDeprecatedAction(action, value) {
      var actionName = this.get('attrs.' + action);
      if (!isEmpty(actionName)) {
        Ember['default'].Logger.warn('DEPRECATION (ember-cli-nouislider): "' + action + '" action is deprecated in favor of "on-' + action + '". Support for "' + action + '" will be dropped in 1.0');
        this.sendAction(action, value);
      }
    }
  });

});