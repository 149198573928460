define('ember-notify/index', ['exports', 'ember', 'ember-new-computed'], function (exports, Ember, computed) {

  'use strict';

  function aliasToShow(type) {
    return function (message, options) {
      return this.show(type, message, options);
    };
  }

  var Notify = Ember['default'].Object.extend({

    info: aliasToShow('info'),
    success: aliasToShow('success'),
    warning: aliasToShow('warning'),
    alert: aliasToShow('alert'),
    error: aliasToShow('error'),

    init: function init() {
      this.pending = [];
    },

    show: function show(type, message, options) {
      var _this = this;

      if (typeof message === 'object') {
        options = message;
        message = null;
      }
      message = Ember['default'].merge({
        message: message,
        type: type
      }, options);
      var target = this.get('target');
      var promise;
      if (target) {
        var messageObj = target.show(message);
        promise = Ember['default'].RSVP.resolve(messageObj);
      } else {
        promise = new Ember['default'].RSVP.Promise(function (resolve) {
          return _this.pending.push({
            message: message,
            resolve: resolve
          });
        });
      }
      return MessagePromise.create({
        message: message,
        promise: promise
      });
    },

    create: function create(component) {
      return Notify.create({
        target: component
      });
    },

    showPending: Ember['default'].observer('target', function () {
      var target = this.get('target');
      if (target) {
        this.pending.map(function (pending) {
          var messageObj = target.show(pending.message);
          pending.resolve(messageObj);
        });
        this.pending = [];
      }
    })

  }).reopenClass({
    // set to true to disable testing optimizations that are enabled when
    // Ember.testing is true
    testing: false
  });

  exports['default'] = Notify.extend({
    property: function property() {
      return Ember['default'].computed(function () {
        return Notify.create();
      });
    },
    create: function create() {
      return Notify.create();
    },
    target: computed['default']({
      get: function get() {
        return this._target;
      },
      set: function set(key, val) {
        Ember['default'].assert("Only one {{ember-notify}} should be used without a source property. " + "If you want more than one then use {{ember-notify source=someProperty}}", !this._primary || this._primary.get('isDestroyed'));
        this._target = val;
        return this._target;
      }
    })

  }).create();

  var MessagePromise = Ember['default'].ObjectProxy.extend(Ember['default'].PromiseProxyMixin, {
    set: function set(key, val) {
      // if the message hasn't been displayed then set the value on the message hash
      if (!this.get('content')) {
        this.message[key] = val;
        return this;
      } else {
        return this._super(key, val);
      }
    }
  });

});