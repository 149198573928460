define('ember-notify/components/ember-notify', ['exports', 'ember', 'ember-notify', 'ember-notify/message'], function (exports, Ember, Notify, Message) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    source: null,
    messages: null,
    closeAfter: 2500,

    classNames: ['ember-notify-cn'],
    messageStyle: 'foundation',

    init: function init() {
      this._super();
      this.set('messages', Ember['default'].A());

      if (Ember['default'].isNone(this.get('source'))) this.set('source', Notify['default']);

      var style = this.get('messageStyle'),
          klass;
      if (style) {
        if ('foundation' === style) klass = FoundationView;else if ('bootstrap' === style) klass = BootstrapView;else if ('refills' === style) klass = RefillsView;else throw new Error('Unknown messageStyle ' + style + ': options are \'foundation\' and \'bootstrap\'');
      }
      this.set('messageClass', klass || this.constructor.defaultViewClass);
    },
    didInsertElement: function didInsertElement() {
      this.set('source.target', this);
    },
    willDestroyElement: function willDestroyElement() {
      this.set('source.target', null);
    },
    show: function show(message) {
      if (this.get('isDestroyed')) return;
      if (!(message instanceof Message['default'])) {
        message = Message['default'].create(message);
      }
      this.get('messages').pushObject(message);
      return message;
    }
  });

  var MessageView = Ember['default'].View.extend({
    message: null,
    closeAfter: null,

    classNames: ['ember-notify'],
    classNameBindings: ['typeCss', 'message.visible:ember-notify-show:ember-notify-hidden'],
    attributeBindings: ['data-alert'],
    'data-alert': '',

    run: null,

    init: function init() {
      this._super();
      this.run = Runner.create({
        // disable all the scheduling in tests
        disabled: Ember['default'].testing && !Notify['default'].testing
      });
    },
    didInsertElement: function didInsertElement() {
      var element = this.get('message.element');
      if (element) {
        this.$('.message').append(element);
      }
      if (Ember['default'].isNone(this.get('message.visible'))) {
        // the element is added to the DOM in its hidden state, so that
        // adding the 'ember-notify-show' class triggers the CSS transition
        this.run.next(this, function () {
          if (this.get('isDestroyed')) return;
          this.set('message.visible', true);
        });
      }
      var closeAfter = this.get('message.closeAfter');
      if (closeAfter === undefined) closeAfter = this.get('closeAfter');
      if (closeAfter) {
        this.run.later(this, function () {
          if (this.get('isDestroyed')) return;
          this.send('close');
        }, closeAfter);
      }
    },
    typeCss: Ember['default'].computed('message.type', function () {
      var cssClass = this.get('message.type');
      if (cssClass === 'error') cssClass = 'alert error';
      return cssClass;
    }),
    visibleObserver: Ember['default'].observer('message.visible', function () {
      if (!this.get('message.visible')) {
        this.send('close');
      }
    }),

    actions: {
      close: function close() {
        var that = this;
        var removeAfter = this.get('message.removeAfter') || this.constructor.removeAfter;
        if (this.get('message.visible')) {
          this.set('message.visible', false);
        }
        if (removeAfter) {
          this.run.later(this, remove, removeAfter);
        } else {
          remove();
        }
        function remove() {
          if (this.get('isDestroyed')) return;
          var parentView = that.get('parentView');
          if (parentView) {
            parentView.get('messages').removeObject(that.get('message'));
            that.set('message.visible', null);
          }
        }
      }
    }
  }).reopenClass({
    removeAfter: 250 // allow time for the close animation to finish
  });

  var FoundationView = MessageView.extend({
    classNames: ['alert-box'],
    classNameBindings: ['radius::']
  });

  var BootstrapView = MessageView.extend({
    classNames: ['alert'],
    typeCss: Ember['default'].computed('type', function () {
      var type = this.get('message.type');
      if (type === 'alert' || type === 'error') type = 'danger';
      return 'alert-' + type;
    })
  });

  var RefillsView = MessageView.extend({
    typeCss: Ember['default'].computed('type', function () {
      var type = this.get('message.type');
      var typeMapping = {
        'success': 'success',
        'alert': 'error',
        'error': 'error',
        'info': 'notice',
        'warning': 'alert'
      };

      return 'flash-' + typeMapping[type];
    })
  });

  var Runner = Ember['default'].Object.extend({
    init: function init() {
      if (!this.disabled) {
        // this is horrible but this avoids delays from the run loop
        this.next = function (ctx, fn) {
          var args = arguments;
          setTimeout(function () {
            Ember['default'].run(function () {
              fn.apply(ctx, args);
            });
          }, 0);
        };
        this.later = function () {
          Ember['default'].run.later.apply(Ember['default'].run, arguments);
        };
      } else {
        this.next = this.later = function zalkoBegone(ctx, fn) {
          Ember['default'].run.next(ctx, fn);
        };
      }
    }
  });

  exports.MessageView = MessageView;
  exports.FoundationView = FoundationView;
  exports.BootstrapView = BootstrapView;
  exports.RefillsView = RefillsView;

});