define('ember-cli-select-picker/mixins/select-picker', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var selectOneOf = function selectOneOf(someSelected, allSelected, noneSelected) {
    return Ember['default'].computed('hasSelectedItems', 'allItemsSelected', function () {
      if (this.get('allItemsSelected')) {
        return allSelected.call(this);
      } else if (this.get('hasSelectedItems')) {
        return someSelected.call(this);
      } else {
        return noneSelected.call(this);
      }
    });
  };

  var selectOneOfValue = function selectOneOfValue(someSelectedValue, allSelectedValue, noneSelectedValue) {
    return selectOneOf(function () {
      return someSelectedValue;
    }, function () {
      return allSelectedValue;
    }, function () {
      return noneSelectedValue;
    });
  };

  var selectOneOfProperty = function selectOneOfProperty(someSelectedKey, allSelectedKey, noneSelectedKey) {
    return selectOneOf(function () {
      return this.get(someSelectedKey);
    }, function () {
      return this.get(allSelectedKey);
    }, function () {
      return this.get(noneSelectedKey);
    });
  };

  var isAdvancedSearch = function isAdvancedSearch(liveSearch) {
    return Ember['default'].typeOf(liveSearch) === 'string' && liveSearch.toLowerCase() === 'advanced';
  };

  var SelectPickerMixin = Ember['default'].Mixin.create({
    liveSearch: false,
    showDropdown: false,

    menuButtonId: Ember['default'].computed('elementId', function () {
      return this.get('elementId') + '-dropdown-menu';
    }),

    selectionAsArray: function selectionAsArray() {
      var selection = this.get('selection');
      // Ember.Select can set the value of selection to
      // any of null, [], [Object, ...], or Object
      if (Ember['default'].isNone(selection)) {
        return Ember['default'].A();
      }
      if (Ember['default'].isArray(selection)) {
        return Ember['default'].A(selection);
      }
      return Ember['default'].A([selection]);
    },

    contentList: Ember['default'].computed('selection.@each', 'content.@each', 'optionGroupPath', 'optionLabelPath', 'optionValuePath', 'searchFilter', function () {
      // Ember.Select does not include the content prefix for optionGroupPath
      var groupPath = this.get('optionGroupPath');
      // Ember.Select expects optionLabelPath and optionValuePath to have a
      // `content.` prefix
      var labelPath = this.contentPathName('optionLabelPath');
      var valuePath = this.contentPathName('optionValuePath');
      // selection is either an object or an array of object depending on the
      // value of the multiple property. Ember.Select maintains the value
      // property.
      var selection = this.selectionAsArray();
      var searchMatcher = this.makeSearchMatcher();

      var result = Ember['default'].A(this.get('content')).map(function (item) {
        var label = Ember['default'].get(item, labelPath);
        var value = Ember['default'].get(item, valuePath);
        var group = groupPath ? Ember['default'].get(item, groupPath) : null;
        if (searchMatcher(group) || searchMatcher(label)) {
          return Ember['default'].Object.create({
            item: item,
            group: group,
            label: label,
            value: value,
            selected: selection.contains(item)
          });
        } else {
          return null;
        }
      });

      // Ember Addons need to be coded as if Ember.EXTEND_PROTOTYPES = false
      // Because of this we need to manually extend our native array from the
      // above map() function. Even though compact() is an Ember function it
      // too sufferes from the same fate.
      result = Ember['default'].A(Ember['default'].A(result).compact());

      if (!Ember['default'].isEmpty(result)) {
        result.get('firstObject').set('first', true);
      }

      return result;
    }),

    groupedContentListWithoutActive: Ember['default'].computed('contentList.@each.group', function () {
      var lastGroup;
      var result = Ember['default'].A(this.get('contentList'));
      result.forEach(function (item) {
        var group = item.get('group');
        if (group === lastGroup) {
          item.set('group', null);
        } else {
          lastGroup = group;
        }
      });
      return result;
    }),

    groupedContentList: Ember['default'].computed.alias('groupedContentListWithoutActive'),

    contentPathName: function contentPathName(pathName) {
      return this.getWithDefault(pathName, '').substr(8);
    },

    getByContentPath: function getByContentPath(obj, pathName) {
      return Ember['default'].get(obj, this.contentPathName(pathName));
    },

    selectedContentList: Ember['default'].computed.filterBy('contentList', 'selected'),
    unselectedContentList: Ember['default'].computed.setDiff('contentList', 'selectedContentList'),
    hasSelectedItems: Ember['default'].computed.gt('selection.length', 0),
    allItemsSelected: Ember['default'].computed('selection.length', 'content.length', function () {
      return Ember['default'].isEqual(this.get('selection.length'), this.get('content.length'));
    }),

    glyphiconClass: selectOneOfValue('glyphicon-minus', 'glyphicon-ok', ''),
    selectAllNoneLabel: selectOneOfProperty('selectNoneLabel', 'selectNoneLabel', 'selectAllLabel'),

    makeSearchMatcher: function makeSearchMatcher() {
      var searchFilter = this.get('searchFilter');
      // item can be null, string, or SafeString.
      // SafeString does not have toLowerCase() so use toString() to
      // normalize it.
      if (Ember['default'].isEmpty(searchFilter)) {
        return function () {
          return true; // Show all
        };
      } else if (isAdvancedSearch(this.get('liveSearch'))) {
          searchFilter = new RegExp(searchFilter.split('').join('.*'), 'i');
          return function (item) {
            if (Ember['default'].isNone(item)) {
              return false;
            } else {
              return searchFilter.test(item.toString());
            }
          };
        } else {
          searchFilter = searchFilter.toLowerCase();
          return function (item) {
            if (Ember['default'].isNone(item)) {
              return false;
            } else {
              return item.toString().toLowerCase().indexOf(searchFilter) >= 0;
            }
          };
        }
    },

    selectionLabels: Ember['default'].computed.mapBy('selectedContentList', 'label'),

    selectionSummary: Ember['default'].computed('selectionLabels.[]', 'nothingSelectedMessage', 'summaryMessage', 'summaryMessageKey', function () {
      var selection = this.get('selectionLabels');
      var count = selection.get('length');
      var messageKey = this.get('summaryMessageKey');
      if (Ember['default'].I18n && Ember['default'].isPresent(messageKey)) {
        // TODO: Allow an enablePrompt="false" feature
        if (count === 0) {
          return this.get('nothingSelectedMessage');
        }
        var item = selection.get('firstObject');
        var translation = Ember['default'].I18n.t(messageKey, {
          count: count,
          item: item,
          list: selection.join(', ')
        });
        // If the item we're inserting into our selection message was a
        // SafeString then then translation needs to be marked as well in order
        // for any html in the original not to get escaped.
        if (item && item.toHTML) {
          translation = Ember['default'].String.htmlSafe(translation);
        }
        return translation;
      }
      switch (count) {
        case 0:
          return this.get('nothingSelectedMessage');
        case 1:
          return selection.get('firstObject');
        default:
          return Ember['default'].String.fmt(this.get('summaryMessage'), count, selection.get('firstObject'), selection.join(', '));
      }
    }),

    clearSearchDisabled: Ember['default'].computed.empty('searchFilter'),

    toggleSelection: function toggleSelection(value) {
      var selection = this.get('selection');
      if (selection.contains(value)) {
        selection.removeObject(value);
      } else {
        selection.pushObject(value);
      }
    },

    actions: {
      selectItem: function selectItem(selected) {
        if (!this.get('disabled')) {
          if (this.get('multiple')) {
            this.set('keepDropdownOpen', true);
            this.toggleSelection(selected.get('item'));
          } else {
            this.set('selection', selected.get('item'));
          }
        }
        return false;
      },

      selectAllNone: function selectAllNone(listName) {
        var _this = this;
        this.get(listName).forEach(function (item) {
          _this.send('selectItem', item);
        });
        return false;
      },

      toggleSelectAllNone: function toggleSelectAllNone() {
        var listName;
        if (this.get('hasSelectedItems')) {
          listName = 'selectedContentList';
        } else {
          listName = 'unselectedContentList';
        }
        this.send('selectAllNone', listName);
        return false;
      },

      clearFilter: function clearFilter() {
        this.set('searchFilter', null);
        return false;
      }
    }
  });

  exports['default'] = SelectPickerMixin;

});